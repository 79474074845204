import Footer from "./Footer";
import Header from "./Header";
import Hero from "./Hero";
import Price from "./Price";

const FirstPage = () => {
  return (
    <div className="h-screen flex flex-col">
      <Header />
      <Hero />
      <Price />
      <Footer />
    </div>
  );
};

export default FirstPage;
